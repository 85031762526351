import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { UX, UX2, constants } from '@wsb/guac-widget-core';
import PortalContainer from '../PortalContainer';
import { isDeviceMobile } from '../utils/viewDevice';

class SlideshowArrows extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      loaded: false
    };
  }

  goToPrevSlide = e => {
    e.stopPropagation();
    this.props.prevSlide();
  }

  goToNextSlide = e => {
    e.stopPropagation();
    this.props.nextSlide();
  }

  handleMatchMedia = data => {
    const { viewDevice, renderMode } = this.props;
    const isMobile = (renderMode === constants.renderModes.PUBLISH) ? data.size === 'xs' : isDeviceMobile(viewDevice);
    if (!this.state.loaded || this.state.isMobile !== isMobile) {
      this.setState({
        loaded: true,
        isMobile
      });
    }
  }

  render() {
    const { numSlides, editingIndex, visible, containerId, mobileContainerId } = this.props;
    const { isMobile, loaded } = this.state;

    if (numSlides <= 1) {
      return null;
    }

    const styles = {
      overlay: {
        'display': (visible && loaded) ? 'flex' : 'none',
        'justifyContent': 'center',
        'position': 'absolute',
        '@xs-only': {
          bottom: 0,
          left: 'small',
          right: 'small',
          marginBottom: 'small'
        },
        '@sm': {
          left: 0,
          right: 0,
          top: 'calc(50% - 20px)' // avoid using transform to keep arrows in base z stacking context
        }
      }
    };

    const dataRouteProps = editingIndex >= 0 ? {
      ['data-route']: '/mediaData'
    } : {};

    const arrowsGroup = (
      <React.Fragment>
        <UX.MatchMedia onChange={ this.handleMatchMedia }/>
        <UX2.Group.SlideshowArrows>
          <UX2.Element.Button.Previous
            onClick={ this.goToPrevSlide }
            data-edit-interactive={ true }
            { ...dataRouteProps }
          />
          <UX2.Element.Button.Next
            onClick={ this.goToNextSlide }
            data-edit-interactive={ true }
            { ...dataRouteProps }
          />
        </UX2.Group.SlideshowArrows>
      </React.Fragment>
    );

    if (isMobile && mobileContainerId) {
      return (
        <PortalContainer containerId={ mobileContainerId }>
          { arrowsGroup }
        </PortalContainer>
      );
    } else if (containerId) {
      return (
        <PortalContainer containerId={ containerId }>
          { arrowsGroup }
        </PortalContainer>
      );
    }

    return (
      <UX2.Element.Block style={ styles.overlay }>
        { arrowsGroup }
      </UX2.Element.Block>
    );
  }
}

SlideshowArrows.propTypes = {
  numSlides: PropTypes.number,
  prevSlide: PropTypes.func,
  nextSlide: PropTypes.func,
  editingIndex: PropTypes.number,
  visible: PropTypes.bool,
  containerId: PropTypes.string,
  mobileContainerId: PropTypes.string,
  renderMode: PropTypes.string,
  viewDevice: PropTypes.string
};

export default SlideshowArrows;
